import React from "react";
import logo from "./logo.png";
import "./App.css";
import googleImage from "./google-play-badge.png";
import appstore from "./appstore.png";
import preview from "./preview.png";
import android from "./android_hero.png";

const Z = () => {
  return (
    <div className="left">
      <img src={android} alt="preview-inmage" />
    </div>
  );
};

export const Footer = () => (
  <div className="footer">
    <p> ©2022 Apps Can Fly, LLC</p>
    <a href="mailto:support@appscanfly.com">
      <i
        className="fa fa-envelope"
        style={{ fontSize: "24px", color: "white", marginRight: "2px" }}
      ></i>
      <p> Contact Us</p>
    </a>
  </div>
);

const Screenshots = () => {
  return (
    <div className="left">
      <img src={preview} alt="preview-inmage" />
    </div>
  );
};
const Info = () => {
  return (
    <div className="right">
      <div className="logo">
        <img src={logo} alt="logo" />
        <span className="logo1">Kids</span>
        <span className="logo2">of</span> <span className="logo1">Mine</span>
      </div>
      <div className="tag">Record important information about your Kids</div>

      <div className="links">
        <a
          href="https://play.google.com/store/apps/details?id=com.appscanfly.kidsofmine"
          target="_blank"
          rel="noreferrer"
        >
          <img src={googleImage} alt="google-play" />
        </a>
        <a
          href="https://apps.apple.com/us/app/kids-of-mine/id6443852022"
          target="_blank"
          rel="noreferrer"
        >
          <img src={appstore} alt="appstore" />
        </a>
      </div>
    </div>
  );
};

const YoutubeEmbed = ({ embedId }: { embedId: string }) => (
  <div className="video-responsive">
    <iframe
      width="560"
      height="315"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

const App = () => {
  return (
    <div className="App">
      <div className="container">
        <Screenshots />
        <Info />
      </div>{" "}
      {/* <YoutubeEmbed embedId="rkPpLWt1b2Y" /> */}
      <Footer />
    </div>
  );
};

export default App;
