import React, { useEffect } from "react";
import logo from "./crowded_pool_logo.svg";
import "./App.css";
import googleImage from "./google-play-badge.png";
import appstore from "./appstore.png";
import preview from "./preview.png";
import { Footer } from "./App";

const Donate = () => {
  useEffect(() => {
    window.location.replace(
      "https://www.paypal.com/donate/?hosted_button_id=4HRPKFYQFVPDQ"
    );
  }, []);
  return (
    <div className="App">
      <div className="container"></div>
      <div
        style={{
          color: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1>Donate </h1>
        <div
          style={{
            backgroundColor: "white",
            width: "50%",
            height: "80vh",
            padding: "30px",
            display: "flex",
            justifyContent: "center",
            margin: "20px",
            color: "black",
          }}
        >
          {/* <h3 style={{ fontWeight: "normal" }}>
            If you're enjoying the App, and would like to support the
            development efforts, fell free to donate down below{" "}
          </h3> */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Donate;
